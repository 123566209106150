<template>
  <div class="d-fdlex flex-column">
    <div class="mt-1 mb-3">Please select whether you want to cancel your plan or delete your account.</div>
    <div class="mb-6 w-50">
      <select
        v-model="selectedTab"
        class="form-control"
        filterable>
        <option value="" disabled selected class="form-control">Select your option</option>
        <option v-for="option in options"
          class="select-danger"
          :value="option.value"
          :label="option.label"
          :key="option.label">
        </option>
      </select>
    </div>
    <div class="d-flex justify-content-end">
      <button
        class="btn btn-outline-primary border-0"
        @click.prevent="$emit('cancel')">
        Cancel
      </button>
      <button class="btn btn-primary" type="submit"
        :disabled="!selectedTab"
        @click.prevent="$emit('changeTab', selectedTab)">
        Continue
      </button>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';

import tabs from '../tabs';
import {accountTypes} from '@/store/userConstants';

export default {
  data: () => ({
    selectedTab: '',
  }),
  computed: {
    ...mapGetters([
      'accountType',

    ]),
    options() {
      const opts = [{value: tabs.accountCancellation, label: 'Delete my Account'}];
      [accountTypes.PERSONAL, accountTypes.TEAM].includes(this.accountType) && opts.push({value: tabs.planCancellation, label: 'Cancel my Plan'});
      return opts;
    },
  },
  methods: {
  },
};
</script>
