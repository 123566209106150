<template>
  <dashboard-page-wrapper title="Account">
    <div class="d-flex flex-column account-page">
      <b-row>
        <b-col lg="7">
          <card :data-id="`${userId}`" bodyClasses="p-0" class="bg-secondary">
            <template slot="header">
              <h3 class="mb-0">Your info</h3>
            </template>
            <ValidationObserver v-slot="{ invalid, validate }" ref="formValidator">
              <b-form @submit.prevent="validate().then(updateProfile)" id="edit-user-form" class="mt-4">
                <div class="px-4">
                  <b-row>
                    <b-col lg="6">
                      <base-input type="text" label="First name" placeholder="First name" v-model="user.firstName"
                        rules="required|min:3" name="First name" group>
                      </base-input>
                    </b-col>
                    <b-col lg="6">
                      <base-input type="text" label="Last name" placeholder="Last name" v-model="user.lastName"
                        rules="required|min:1" name="Last name" group>
                      </base-input>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col lg="6">
                      <base-input type="email" label="Email address" placeholder="Enter email" :value="user.email"
                        rules="required|email" name="Email" disabled group>
                      </base-input>
                    </b-col>
                    <template v-if="showAnalytics">
                      <b-col lg="6" class="mb-4">
                        <!-- <base-input
                            type="text"
                            label="Company"
                            placeholder="Enter your company"
                            :value="user.company"
                            @input="val => user.company = val"
                            rules="required"
                            v-model="user.company"
                            name="company"
                            :disabled="userType === userTypes.MEMBER"
                            group
                          >
                          </base-input> -->
                        <analytics />
                      </b-col>
                    </template>
                    <template v-if="showCallForwarding">
                      <b-col xl="6" class="mt-2">
                        <div class="d-flex">
                          <base-input class="flex-fill" type="phone" label="Call forwarding number" placeholder=""
                            v-model="user.callForwardingNumber" rules="phoneNumber" name="Call forwarding number"
                            :disabled="!user.callForwardingEnabled" @input="acceptNumber"
                            :required="user.callForwardingEnabled" group>
                          </base-input>
                          <base-switch v-model="user.callForwardingEnabled" @input="togglePhone" onText="On"
                            offText="Off" class="phone-switcher ml-3">
                          </base-switch>
                        </div>
                      </b-col>
                    </template>
                    <b-col xl="6">
                      <messageScheduling @onchange="setTimeZone" :userTimezone="userTimezone" />
                    </b-col>
                    <template v-if="!twilioNumberFormatted">
                      <b-col xl="6" class="mt-3">
                        <div class="d-flex align-items-center h-100 pb-3">
                          <base-button type="primary" @click="$router.push({ name: 'Get Number' })">
                            <i class="ni ni-fat-add"></i>Get a number
                          </base-button>
                        </div>
                      </b-col>
                    </template>
                  </b-row>
                </div>
                <hr class="my-4" />
                <div class="d-flex justify-content-end px-4 mb-4">
                  <button class="btn btn-primary" type="submit" form="edit-user-form"
                    :disabled="invalid || btnDisabled">Save
                  </button>
                </div>
              </b-form>
            </ValidationObserver>
          </card>
        </b-col>
        <b-col lg="5">
          <b-row v-if="accountType === accountTypes.TEAM">
            <b-col md="12">
              <card bodyClasses="p-3 pb-3" class="bg-secondary">
                <template slot="header">
                  <h3 class="mb-0">Out of Office</h3>
                </template>
                <base-switch v-model="enableOutOfOffice" onText="On" offText="Off" @input="toggleOutOfOffice">
                </base-switch>
                <div>
                  <b-row class="mt-3">
                    <b-col md="12" class="mb-3">
                      When turned on, all incoming messages will receive this automated reply.
                      <br><br>
                      You will need to refresh your page for the extension to update.
                    </b-col>
                    <b-col md="12">
                      <out-of-office-form :outOfOfficeMessageEnabled="enableOutOfOffice"
                      :outOfOfficeMessage="outOfOfficeMessage" />
                    </b-col>
                  </b-row>
                </div>
              </card>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <div class="cancellation-block pre-md-text" v-if="userType === userTypes.ADMIN">
        <!--        Looking to delete your account or cancel your plan?
        <b @click="showCancellationModal" class="active-element"><u>Start-->
        <!--        here</u></b>.-->
        Looking to delete your account or cancel your plan? <u>Contact support</u>.
      </div>
    </div>
    <invite-myself-modal ref="inviteMyselfModal"></invite-myself-modal>
    <cancellation-modal ref="cancellationModal" />
    <confirmation-modal ref="phoneBuyModal" header="1 hour until you can text"
    question="You’ll need to wait 1 hour for this new number to be fully registered
        with the telco carriers before using." confirmBtnText="Ok" btnClass="justify-content-end" :isClose="true"
      :showCancel="false" @on-accept="updateBuyPhoneState" @on-close="updateBuyPhoneState" />
  </dashboard-page-wrapper>
</template>
<script>
import {mapActions, mapGetters} from 'vuex';
import {
  UPDATE_USER_REQUEST,
  UPDATE_ACCOUNT_REQUEST,
  UPDATE_CALL_FORWARDING_REQUEST,
  UPDATE_USER_OFFICE_MSG_REQUEST,
} from '@/store/storeActions';
import DashboardPageWrapper from '@/components/App/UI/DashboardPageWrapper.vue';
import {accountTypes, userTypes} from '@/store/userConstants';
import InviteMyselfModal from '@/views/Dashboard/team/InviteMyselfModal.vue';
import CancellationModal from './cancellationModal/CancellationModal.vue';
import Analytics from './analytics/Analytics.vue';
import MessageScheduling from './scheduling/MessageScheduling.vue';
import ConfirmationModal from '@/components/App/modals/ConfirmationModal.vue';
import OutOfOfficeForm from '../automations/OutOfOfficeForm.vue';

export default {
  components: {
    DashboardPageWrapper,
    InviteMyselfModal,
    CancellationModal,
    Analytics,
    MessageScheduling,
    ConfirmationModal,
    OutOfOfficeForm,
  },
  data() {
    return {
      user: {
        company: '',
        email: '',
        firstName: '',
        lastName: '',
        callForwardingEnabled: false,
        callForwardingNumber: '',
        timezone: '',
      },
      accountTypes,
      userTypes,
      userLoaded: false,
      userInfoChanged: false,
      accountInfoChanged: false,
      callForwardingChanged: false,
      isNewBuyPhoneNumber: false,
      enableOutOfOffice: false,
    };
  },
  computed: {
    ...mapGetters([
      'userId',
      'userFirstName',
      'userLastName',
      'userEmail',
      'userCompany',
      'userPhoneNumber',
      'userTimezone',
      'accountType',
      'userType',
      'planType',
      'twilioNumberFormatted',
      'callForwardingEnabled',
      'callForwardingNumber',
      'isPaygPlan',
      'totalSeats',
      'availableSeats',
      'isBuyPhoneNumber',
      'outOfOfficeMessageEnabled',
      'outOfOfficeMessage',
    ]),
    btnDisabled: function() {
      return !(this.userInfoChanged || this.accountInfoChanged || this.callForwardingChanged);
    },
    showAnalytics: function() {
      return !this.isPaygPlan && this.twilioNumberFormatted;
    },
    showCallForwarding: function() {
      return !this.isPaygPlan && this.twilioNumberFormatted;
    },
  },
  watch: {
    user: {
      deep: true,
      handler: function(user) {
        this.userInfoChanged = (this.userFirstName !== user.firstName || this.userLastName !== user.lastName ||
        (this.userTimezone !== user.timezone && user.timezone !== undefined) );
        this.callForwardingChanged = (
          (this.callForwardingNumber !== user.callForwardingNumber && user.callForwardingNumber !== '') ||
          this.callForwardingEnabled !== user.callForwardingEnabled
        );
        this.accountInfoChanged = this.userCompany !== user.company;
      },
    },
    isBuyPhoneNumber: {
      handler: function(value) {
        this.isNewBuyPhoneNumber = value;
      },
      immediate: true,
    },
    outOfOfficeMessageEnabled: {
      handler: function(value) {
        this.enableOutOfOffice = value;
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions([
      UPDATE_USER_REQUEST,
      UPDATE_ACCOUNT_REQUEST,
      UPDATE_CALL_FORWARDING_REQUEST,
      UPDATE_USER_OFFICE_MSG_REQUEST,
    ]),
    togglePhone(value) {
      !value && (this.user.callForwardingNumber = '');
    },
    async updateProfile() {
      try {
        if (this.userInfoChanged) {
          const payLoad = {
            firstName: this.user.firstName,
            lastName: this.user.lastName,
            timezone: this.user.timezone,
          };
          await this.UPDATE_USER_REQUEST(payLoad);
        }
        if (this.accountInfoChanged) {
          await this.UPDATE_ACCOUNT_REQUEST({
            name: this.user.company,
          });
        }
        if (this.callForwardingChanged) {
          await this.UPDATE_CALL_FORWARDING_REQUEST({
            callForwardingNumber: this.user.callForwardingNumber,
            callForwardingEnabled: this.user.callForwardingEnabled,
          });
        }
      } finally {
        this.userLoaded = false;
        this.setDataFromStore();
      }
    },
    getCallForwardingData() {
      if (!this.user.callForwardingEnabled) {
        return {
          callForwardingEnabled: this.user.callForwardingEnabled,
        };
      } else {
        return {
          callForwardingEnabled: this.user.callForwardingEnabled,
          callForwardingNumber: this.user.callForwardingNumber,
        };
      }
    },
    setDataFromStore() {
      if (!!this.userId && !this.userLoaded) {
        this.user.firstName = this.userFirstName;
        this.user.lastName = this.userLastName;
        this.user.email = this.userEmail;
        this.user.company = this.userCompany;
        this.user.callForwardingEnabled = this.callForwardingEnabled;
        this.user.callForwardingNumber = this.callForwardingNumber;
        this.userInfoChanged = false;
        this.accountInfoChanged = false;
        this.callForwardingChanged = false;
        this.userLoaded = true;
        this.user.timezone = this.userTimezone;
      }
    },
    acceptNumber() {
      const x = this.user.callForwardingNumber.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
      this.user.callForwardingNumber = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
    },
    showInvitationModal: function() {
      this.$refs.inviteMyselfModal.displayToggle(true);
    },
    showCancellationModal: function() {
      this.$refs.cancellationModal.displayToggle(true);
    },
    setTimeZone(timezone) {
      this.user.timezone = timezone;
    },
    updateBuyPhoneState() {
      this.$store.commit('BUY_PHONE_NUMBER_SUCCESS', {isBuyPhoneNumber: false});
    },
    toggleOutOfOffice: function(value) {
      const payload = {
        outOfOfficeMessageEnabled: this.enableOutOfOffice,
        userId: this.userId,
      };
      this.UPDATE_USER_OFFICE_MSG_REQUEST({payload});
      this.enableOutOfOffice = value;
      this.$store.state.auth.user.outOfOfficeMessageEnabled = value;
    },
  },
  created: function() {
    this.setDataFromStore();
  },
  beforeUpdate: function() {
    this.setDataFromStore();
  },
  mounted: function() {
    if (this.isNewBuyPhoneNumber) this.$refs.phoneBuyModal.openModal();
  },
};
</script>
<style scoped lang="scss">
@import "@/assets/sass/custom/_app-vars.scss";
.profile-header {
  background-image: url(/img/theme/profile-cover.jpg);
  background-size: cover;
  background-position: center top;
  min-height: 500px;
}
.account-page {
  min-height: calc(100vh - 120px);
}
.phone-switcher {
  margin-top: 43px;
}
.cancellation-block {
  color: $gray-600;
}
</style>
