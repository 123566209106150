<template>
  <div class="d-fdlex flex-column">
    <div class="mt-1">
      Deleting  your account will cancel your plan immediately and remove all candidate data and text message history.
      </div>
    <div class="mt-4 mb-5">
      <base-input
        type="text"
        label="Please type your first name below to confirm deletion"
        placeholder="First name"
        v-model="name"
      />
    </div>
    <div class="d-flex justify-content-end">
      <button
        class="btn btn-outline-primary border-0"
        @click.prevent="toMain">
        No, don’t delete my account
      </button>
      <button class="btn btn-danger" type="submit"
        :disabled="!name"
        @click.prevent="deleteAccount">
        Delete account
      </button>
    </div>
  </div>
</template>

<script>

import tabs from '../tabs';
import BaseInput from '@/components/Inputs/BaseInput.vue';
import {mapActions} from 'vuex';
import {DELETE_USER_REQUEST} from '@/store/storeActions';

export default {
  components: {BaseInput},
  data: () => ({
    selectedTab: null,
    name: '',
  }),
  methods: {
    ...mapActions([
      DELETE_USER_REQUEST,
    ]),
    deleteAccount() {
      this.DELETE_USER_REQUEST();
    },
    toMain() {
      this.$emit('changeTab', tabs.main);
    },
  },
};
</script>
