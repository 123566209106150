<template>
  <div>
    <div>
      <label class="form-control-label">Message scheduling time zone</label>
    </div>
    <b-form-input v-model="selected" list="timezone-list" class="w-100" @change="onChange"
      :placeholder="gtmSelected || 'Select timezone'"></b-form-input>

    <datalist id="timezone-list">
      <option v-for="itemzone in timezoneList" :key="itemzone">{{ itemzone }}</option>
    </datalist>
    <div class="mt-3 small-text d-inline">
      <strong>{{ userTimezone || selected }}</strong>
    </div>
  </div>
</template>
<script>
import {timezones} from './timezones.js';

export default {
  props: ['userTimezone'],
  data() {
    return {
      timezoneList: this.getTimezoneList(),
      selected: null,
      gtmSelected: this.getGtmTimeZone(),
      timeZoneValue: this.getTimeZoneKey(this.userTimezone),
    };
  },
  methods: {
    onChange(timezone) {
      const newTimeZone = this.getTimeZoneKey(timezone);
      this.$emit('onchange', newTimeZone);
    },
    getGtmTimeZone() {
      return Object.values(timezones).find((el) => (!this.userTimezone || !this.selected) && el === timezones[this.userTimezone || this.selected]);
    },
    getTimeZoneKey(timezone) {
      return Object.keys(timezones).find((key) => timezones[key] === timezone);
    },
    getTimezoneList() {
      return _.omit(timezones, this.userTimezone);
    },
  },
};
</script>

<style scoped lang="scss">
.small-text {
  font-size: x-small;
}
</style>
