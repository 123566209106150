export default {
  main: {
    name: 'main',
    headerTitle: 'Are you sure you want to cancel or delete?',
    component: 'main-tab',
  },
  accountCancellation: {
    name: 'accountCancellation',
    headerTitle: 'Are you sure you want to delete your account?',
    component: 'account-cancellation',
  },
  planCancellation: {
    name: 'planCancellation',
    headerTitle: 'Are you sure you want to cancel your plan?',
    component: 'plan-cancellation',
  },
};
